import { useLocation } from '@reach/router'
import axios from 'axios'
import { differenceInYears } from 'date-fns'
import { Link } from 'gatsby'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useForm, UseFormMethods } from 'react-hook-form'
import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'
import * as URLS from 'src/config/api/Urls'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { getParameterByName, sendCDPFormData } from 'src/shared/helpers'

import MSG from './msgAccount.json'

import useWidth from 'src/hooks/window/useWidth'

import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import errorImg from 'src/assets/images/shared/erro-pig.png'
import OpenCommunity from 'src/assets/images/shared/OpenCommunityQrCode.png'
import QRCodeFortaleza from './img/QRCode_fortaleza.svg'
import sucesso from './img/sucesso.png'

import { CloseButton, Container } from './style'

type SocioTorcedorOpenAccountRightFormProps = {
  closeModal: () => void;
  customOrigin?: string;
  pageMei?: boolean;
  sectionOrder: string;
  dataLayer: IDataLayerParams;
}

interface IFormValues {
  nome: string;
  email: string;
  celular: string;
  cpf: string;
  dataNascimento: string;
}

function SocioTorcedorOpenAccountRightForm ({ closeModal, customOrigin, pageMei, sectionOrder, dataLayer }: SocioTorcedorOpenAccountRightFormProps) {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ sendDatalayerEvent ] = useDataLayer()
  const location = useLocation()
  const WIDTH_MD = 1024
  const windowWidth = useWidth(200)
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ hasAccount, setHasAccount ] = useState(false)
  const [ underAge, setUnderAge ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ filled, setFilled ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ message, setMessage ] = useState(MSG.PF)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const qrcodeDeeplink = 'https://bancointer.go.link/?adj_t=15zbnjfh&adj_fallback=https%3A%2F%2Fgointer.app%2F&adj_redirect_macos=https%3A%2F%2Fgointer.app%2F&adj_adgroup=cartao&adj_creative=athleticoparanaense'

  const SuccessMessageMEI = location.pathname.includes('/empresas/conta-digital/mei/')
  const publicOfferings = location.pathname.includes('/pra-voce/investimentos/ofertas-publicas')
  const InvestmentCommunity = location.pathname.includes('/pra-voce/investimentos/comunidade-de-investimentos')

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))

    if (SuccessMessageMEI) {
      setMessage(MSG.MEI)
    } if (publicOfferings) {
      setMessage(MSG.offers)
    }
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)
    const newDate = data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join(',')
    const result = differenceInYears(new Date(), new Date(newDate))

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoAceito: accept,
      origin: customOrigin || 'site-institucional',
      time: 'FORTALEZA',
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    try {
      if (result < 18) {
        setUnderAge(true)
      } else {
        await axios.put(`${URLS.OPEN_ACCOUNT_V2}`, formData)
        setLoading(false)
        setSent(true)
        setLoading(false)
        sendCDPFormData({ formName: 'Formulario abertura de conta', cpf: formData.cpf, email: formData.email })
        sendDatalayerEvent({
          section: `m_${sectionOrder}`,
          element_action: 'submit',
          element_name: 'Continuar',
          element_previous: dataLayer.element_previous,
          section_name: dataLayer.section_name,
          step: 'success',
        })
      }
    } catch (e) {
      setError(true)
      setLoading(false)
      setFilled(false)
      sendDatalayerEvent({
        section: `m_${sectionOrder}`,
        element_action: 'submit',
        element_name: 'Continuar',
        element_previous: dataLayer.element_previous,
        section_name: dataLayer.section_name,
        step: 'success',
      })
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
    setFilled(false)
  }

  const asFilled = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = qrcodeDeeplink
    } else {
      setSent(false)
      setFilled(true)
    }
  }

  const asUnderAge = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = qrcodeDeeplink
    } else {
      window.location.href = '/pra-voce/conta-digital/conta-kids/'
    }
  }

  const handleHasAccount = () => {
    setHasAccount(!hasAccount)
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-4' onClick={closeModal}>
      <Close width='24' height='24' color={` ${pageMei ? 'grayscale--500' : 'orange--extra'}`} />
    </CloseButton>
  )

  return (
    <>
      { error && (
        <Container isModal={closeModal} className='d-flex align-items-center'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 mb-5'>Ah não! Ocorreu um erro com a sua solicitação...</h3>
            <img src={errorImg} alt='Imagem de erro' />
            <p className='text-grayscale--400 fs-18 lh-22 pt-3'>
              Verifique a sua conexão com a internet e tente novamente. <br />
              <button onClick={handleReturn} title='Clique aqui para voltar' className='bg-transparent border-0 fw-600'>
                Clique aqui
              </button> para voltar.
            </p>
          </div>
        </Container>
      )}
      { sent && (
        <Container isModal={closeModal} className='d-flex align-items-center sent'>
          {closeButton}
          <div className='col-12 text-center'>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400 fw-600'>
              Prontinho! Recebemos os seus dados.
            </p>
            <h3 className='fs-24 lh-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-block'>Agora <span className='text-orange--extra'><a target='_blank' href='https://bancointer.go.link/?adj_t=15m1rgtz&adj_fallback=https%3A%2F%2Fgointer.app%2F%3Futm_source%3Dsite%26utm_medium%3Dbotao%26utm_campaign%3Dsite_cartao_fortaleza&adj_redirect_macos=https%3A%2F%2Fgointer.app%2F%3Futm_source%3Dsite%26utm_medium%3Dbotao%26utm_campaign%3Dsite_cartao_fortaleza&adj_adgroup=cartao&adj_creative=fortaleza' rel='noreferrer'>baixe nosso app,</a></span></span>
              e abra sua conta gratuita
              <span className='d-block'>{publicOfferings ? ' e invista nas Ofertas Públicas.' : ''}</span>
            </h3>
            <div className='col-12 pb-2 mx-auto d-none d-lg-block'>
              <img src={QRCodeFortaleza} alt='Baixe o App Inter' />
            </div>
            <div className='col-12'>
              <hr />
              <p className='fs-14 lh-17 text-left'>
                { message }
              </p>
            </div>
          </div>
        </Container>
      )}
      { hasAccount && (
        <Container isModal={closeModal} className='d-flex align-items-center sent'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-24 lh-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-block'>Acesse as <span className='text-orange--extra'>comunidades de investimentos no app</span> e </span> crie ou entre em alguma.
            </h3>
            <div className='col-12 pb-2 mx-auto d-lg-none'>
              <img src={sucesso} alt='Sucesso' />
            </div>
            <div className='col-12 pb-2 mx-auto d-none d-lg-block'>
              <img src={OpenCommunity} alt='Entrar em comunidades' />
            </div>
            <div className='col-12 d-none d-lg-block'>
              <hr />
              <p className='fs-14 lh-17 text-left'>
                { message }
              </p>
            </div>
            <button onClick={asFilled} className='fs-16 fw-600 text-white btn bg-orange--linear rounded-2 mx-auto d-lg-none mt-3'>
              Baixar o app agora
            </button>
          </div>
        </Container>
      )}
      { underAge && (
        <Container isModal={closeModal} className='d-flex align-items-center under-age'>
          {closeButton}
          <div className='col-12 text-md-center text-lg-left'>
            <h3 className='fs-16 lh-20 text-grayscale--400 fw-400 fw-md-600'>
              Vimos que você tem menos de 18 anos.
            </h3>
            <p className='fs-20 lh-23 fs-md-24 lh-md-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-block'>
                Conheça nossa
                <span
                  className='text-orange--extra cursor-pointer'
                  onClick={asUnderAge}
                > Conta Kids
                </span>,
              </span>
              que também é completa, digital e gratuita.
            </p>
            <div className='col-12 pb-2 mx-auto d-lg-none text-center'>
              <img src={sucesso} alt='Sucesso' />
            </div>
            <div className='col-12 pb-2 mx-auto d-none d-lg-block text-center'>
              <img src={QRCodeFortaleza} alt='Baixe o App Inter' />
            </div>
            <div className='col-12 d-none d-lg-block px-0'>
              <hr />
              <p className='fs-14 lh-17 text-left'>
                Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e
                clicar em ”Abrir conta completa e gratuita”. É rápido e fácil!
              </p>
              <p className='fs-14 lh-17 text-left'>
                De acordo com nossa Política de Privacidade, não armazenamos dados de menores de 18 anos
                sem o consentimento dos responsáveis.
              </p>
            </div>
          </div>
        </Container>
      )}
      {filled && (
        <Container isModal={closeModal} className='d-flex align-items-center filled'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 fw-600 text-grayscale--500'>
              Baixe o app e vá em <br /> ”Abrir uma conta gratuita”
            </h3>
            <p className='fs-16 lh-20 text-grayscale--400 mb-5'>
              {MSG.filled}
            </p>
            <div className='col-12'>
              <img src={QRCodeFortaleza} alt='Baixe o App Inter' />
            </div>
          </div>
        </Container>
      )}
      <Container isModal={closeModal} className={`${error || sent || filled || underAge || hasAccount ? 'd-none' : 'd-flex'} align-items-start align-items-md-center`}>
        {closeButton}
        <div className='row'>
          <div className='col-12 pt-lg-5'>
            <h2 className='font-sora fs-24 lh-30 text-left text-md-center mb-4 text-grayscale--500 fw-600'>
              Abra agora sua Conta Digital
            </h2>
            <p className='font-sora fs-16 lh-20 fw-600 text-grayscale--400 mb-4'>
              Já tem uma conta Inter? Basta solicitar o cartão do Fortaleza com a Babi no chat.
            </p>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='col-12 d-flex flex-column px-0  mb-3 pb-1'>
                  <label htmlFor='nome' className='fs-12 lh-15 fw-600'>Nome</label>
                  <input
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome'
                  />
                  {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0  mb-3 pb-1'>
                  <label htmlFor='celular' className='fs-12 lh-15 fw-600'>Telefone</label>
                  <input
                    ref={register({
                      required: 'Digite um telefone válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name='celular'
                    id='celular'
                    type='tel'
                    placeholder='Digite seu telefone'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  {errors.celular && <p className='fs-12 text-red--base mb-0 text-right'>{errors.celular.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0  mb-3 pb-1'>
                  <label htmlFor='email' className='fs-12 lh-15 fw-600'>E-mail</label>
                  <input
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0  mb-3 pb-1'>
                  <label htmlFor='cpf' className='fs-12 lh-15 fw-600'>CPF</label>
                  <input
                    ref={register({
                      required: 'Digite um CPF válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder='Digite seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors.cpf && <p className='fs-12 text-red--base mb-0 text-right'>{errors.cpf.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0  pb-1'>
                  <label htmlFor='dataNascimento' className='fs-12 lh-15 fw-600'>Data de Nascimento</label>
                  <input
                    ref={register({
                      required: 'Digite uma data válida',
                      validate: {
                        isDate: (value: string) => Validations.dateValid(value) || 'Data Inválida',
                      },
                    })}
                    name='dataNascimento'
                    id='dataNascimento'
                    type='tel'
                    placeholder='dd/mm/aaaa'
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))}
                  />
                  {errors.dataNascimento && <p className='fs-12 text-red--base mb-0 text-right'>{errors.dataNascimento.message}</p>}
                </div>

                <div className='col-12 d-flex flex-column px-0'>
                  <AcceptTerms
                    accept={accept} setAccept={setAccept}
                    name='conta-digital-pf' dataLayer={dataLayer}
                  />
                </div>
                {InvestmentCommunity ? (
                  <div className='col-12 text-center'>
                    <div className='row'>
                      <div className='col-6'>
                        <button
                          type='button' onClick={handleHasAccount}
                          className='btn btn-white border border-orange--extra rounded-2 fs-14 fw-600 mx-auto text-orange--extra text-none'
                        >
                          Já sou cliente
                        </button>
                      </div>
                      <div className='col-6'>
                        <button
                          type='submit' title='Enviar contato' disabled={!accept || loading}
                          className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                        >
                          {loading ? 'Enviando...' : 'Continuar'}
                        </button>
                      </div>
                    </div>
                  </div>
                  ) : (
                    <div className='col-12 text-center'>
                      <button
                        type='submit' title='Enviar contato' disabled={!accept || loading}
                        className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                      >
                        {loading ? 'Enviando...' : 'Continuar'}
                      </button>
                    </div>
                )}
              </div>
            </form>
            <div className='text-center pt-3'>
              <span className='fs-14 lh-18 text-grayscale--400'>
                Já preencheu?
                <button
                  onClick={asFilled}
                  className='text-orange--extra fw-600 bg-transparent border-0 ml-1'
                > Agora baixe o app
                </button>
              </span>
              {
                InvestmentCommunity && (
                  <Link
                    to='/pra-voce/investimentos/'
                    className='fs-16 fw-600 lh-20 text-orange--extra pt-4 d-block'
                  > Saiba mais sobre Investimentos
                  </Link>
                )
              }
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default SocioTorcedorOpenAccountRightForm
